.tooltip {
  @apply z-10 pointer-events-none;

  &.reports {
    z-index: 1000;
  }

  &.custom-metrics {
    z-index: 100;
  }

  &.checkbox {
    z-index: 1000;
  }

  .tooltip-inner {
    @apply bg-warning-100 rounded-[8px] text-sm px-4 py-3 text-neutral-900 max-w-[200px] shadow-floating;
  }

  .tooltip__header {
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
  }

  .tooltip__bold {
    font-weight: 500;
  }

  .tooltip__processing {
    display: block;
    color: #8798ac;
    padding-left: 15px;
    background: url('../assets/images/base-icons/clock.svg') left 1px no-repeat;
    background-size: 10px;
    font-size: 11px;
    font-weight: 500;
    margin-top: 5px;
  }

  .tooltip__note {
    margin-top: 5px;
    display: block;
  }

  .tooltip__error {
    display: block;
    margin-top: 5px;
    color: #ff2373;
  }

  .tooltip-arrow {
    width: 1px;
    height: 1px;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #ffffcf;
  }

  &.custom-arrow {
    .tooltip-arrow {
      left: 17px !important;
    }
  }

  &[x-placement^="left"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      left: calc(50% - 5px);
      bottom: -5px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="top"] {
    margin: 0 0 5px 35px;

    .tooltip-arrow {
      border-width: 5px 5px 0;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 50px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 15px;

    .tooltip-arrow {
      border-width: 0 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 50px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.tooltip--contain-content {
  width: fit-content;

  .tooltip-inner {
    max-width: none;
  }
}
