@import 'fonts';
@import 'variables';
@import 'grid';
@import 'common-style';
@import 'icons';
@import 'tooltips';
@import 'vselects';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: $font-family;
    background: $body-background;
    color: $main-text-color;
    font-size: 14px;
    height: 100%;
    min-height: 100%;
    overflow-x: hidden;
    margin: 0;
    display: flex;
  }

  html {
    height: 100%;
  }

  button {
    border: none;
    outline: none;
  }

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $main-text-color;
    }
  }

  h1 {
    @apply text-h1;
  }

  h2 {
    @apply text-h2;
  }

  h3 {
    @apply text-h3;
  }

  h4 {
    @apply text-h4;

    color: $main-text-color;
  }

  h5 {
    @apply text-h5;

    color: $secondary-color;
  }

  h6 {
    @apply text-h6;

    color: $secondary-color;
  }

  p {
    @apply text-md;

    margin: 0;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  .primary-color {
    color: $primary-color;
  }

  .secondary-color {
    color: $secondary-color;
  }

  .link--primary {
    color: $primary-color;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    transition: transform 10ms linear;

    &:hover,
    &:active,
    &:focus {
      color: $primary-link-active-color;
    }

    &:active {
      transform: translateY(2px);
    }

    &:disabled {
      color: $border-color;
    }
  }

  .link--white {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    transition: transform 10ms linear;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      color: #e6e6e6;
    }

    &:active {
      transform: translateY(2px);
    }

    &:disabled {
      color: $border-color;
    }
  }

  .icon--big {
    width: 50px;
  }

  .icon--medium {
    width: 40px;
  }

  .cd-avatar {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .no-margin {
    margin: 0;
  }

  .is-sticky {
    position: sticky;
    transition: all 0.35s ease;
  }
}
