
.overlay__text ::v-deep a {
  font-weight: 500;
  color: #3152dc;
}

.qr-code__form {
  @apply mt-6;
}

.overlay__button-wrapper {
  @apply pt-0;
}
