
.login-form__forgot-password {
  float: right;
  font-weight: 500;
}

.login-form__register {
  font-weight: 500;
}

.login-form__checkbox {
  margin-bottom: 30px;
}
