
.registration-form {
  max-width: 404px;

  ::v-deep .image-preview {
    border: 2px #e5ebf0 solid;
  }

  .member-details__info-detail {
    margin-bottom: 10px;
  }

  .member-details__info-description {
    margin-bottom: 30px;
  }
}
