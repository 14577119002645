
.footer {
  text-align: center;
  left: 80px;
  width: calc(100% - 80px);
  padding-bottom: 30px;

  &.footer--welcome-page {
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }

  &.footer--no-account {
    left: 0;
    width: 100%;

    .footer__copyright {
      font-size: 12px;
      line-height: 1.5;
      text-align: center;
      color: #a0beff;
      margin: 5px 0 9px;
    }

    .footer__term-policy {
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      color: #fcfdfd;

      > a:hover {
        color: #a0beff;
      }
    }

    .footer__logo {
      background-image: url("../../../assets/images/logos/measure-studio-logo.svg?fill=#a1c0fd");
    }

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  &.footer--pre-pages {
    position: relative;
    left: 0;
    width: 100%;
    margin-top: 60px;
  }

  &.footer--flex-bottom {
    padding: 0;
  }
}

.footer__logo {
  width: 21px;
  height: 21px;
  margin: auto;
  background-size: 21px;
  background-image: url("../../../assets/images/logos/measure-studio-logo.svg");
}

.footer__copyright {
  font-size: 14px;
  line-height: 0.86;
  color: #8798ac;
  margin: 12px 0;
}

.footer__term-policy {
  font-size: 14px;
  font-weight: 500;
  line-height: 0.86;
  color: #3152dc;

  > a:active {
    display: inline-block;
    outline: none;
    transform: translateY(2px);
  }

  > a:hover {
    color: #0121a8;
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    padding-bottom: 30px;
    left: 0;
    width: 100%;
  }

  .footer__copyright {
    margin: 19px 0 15px;
  }

  .footer__term-policy,
  .footer__copyright {
    font-size: 12px;
  }
}

.footer--white {
  position: static;
  margin: 30px auto;

  .footer__logo {
    background-image: url("../../../assets/images/logos/measure-studio-logo.svg?fill=#daedf8");
  }

  .footer__copyright,
  .footer__term-policy {
    color: #daedf8;
  }
}
