.v-select {
  width: 100%;
  background: #fcfdfd;
  border: none;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  height: 48px;

  &:hover {
    background: #fffff5;
  }

  &:focus {
    background: #fffee2;
    outline: none;
  }

  &.open {
    border-radius: 12px 12px 0 0;
  }

  &.open .dropdown-toggle {
    .selected-tag {
      opacity: 1;
    }
  }

  input[type="search"] {
    background: none !important;
    font-weight: 500;
    margin-top: 2px;

    &:focus {
      margin-top: 2px;
    }
  }

  .vs__actions {
    padding-right: 18px;
  }

  ul.dropdown-menu {
    border: none;
    border-top: solid 1px #e5ebf0;
    border-radius: 0 0 12px 12px;
    max-width: 100%;

    @media only screen and (max-width: 767px) {
      width: calc(100vw - 44px);
    }

    li {
      border-bottom: 1px solid #E5EBF0;
      color: #1b2938;
      font-size: 14px;
      line-height: 10px;

      &:hover {
        font-weight: 500;
        color: #1b2938;
      }

      a {
        padding: 15px;
        font-weight: normal;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    > .highlight > a {
      background: #fffff5;
      color: #1b2938;
    }

    > .active > a {
      background-color: #fffff5;
      font-weight: 500;
      color: #1b2938;
    }
  }

  button.clear {
    display: none;
  }

  .dropdown-toggle {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    border-radius: 22px;
    background-color: #fcfdfd;
  }

  .dropdown-menu .highlight a {
    color: #fff;
  }

  .open-indicator::before {
    border-color: #3152dc;
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    transform-origin: 5px 3px;
  }

  .selected-tag {
    position: absolute;
    padding: 0 0 0 20px;
    margin: 2px 0 0;
    border: none;
    opacity: 1;
    bottom: 0;
    top: 0;
    font-size: 14px;
    font-weight: 500;
    color: #1b2938;
  }

  &.disabled .selected-tag {
    font-weight: 400;
    color: #8798ac;
  }

  .vs__selected-options {
    padding: 0;

    .form-control {
      padding-left: 20px;
    }
  }

  input[type="search"],
  input[type="search"]:focus {
    padding-left: 20px;
  }

  .v-select.reversed {
    &.open .dropdown-toggle {
      border-radius: 0 0 12px 12px;
    }

    .dropdown-menu {
      bottom: 100%;
      top: auto;
      border-radius: 12px 12px 0 0;
    }
  }
}

.v-select--bordered {
  border: 2px solid #e5ebf0;

  ul.dropdown-menu {
    box-shadow: 0 3px 6px 0 #e5ebf0;
    padding: 0;
  }
}

.v-select--regular {
  .v-select {
    background: #fcfdfd;
    border: 2px solid #e5ebf0;
    border-radius: 12px;
    height: 50px;

    .selected-tag {
      color: #8798AC;
    }
  }

  .open-indicator::before {
    border-color: #8798AC;
  }
}

.v-select--input-prefix {
  position: static;
  width: 80px;
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  ul.dropdown-menu {
    width: 100%;
    border: 2px #e5ebf0 solid;
    margin-top: -2px;
    max-height: 187px !important;
  }

  .vs__actions {
    padding-right: 6px;
  }

  &.open + input {
    border-bottom-right-radius: 0 !important;
  }
}

.v-select--rounded {
  .v-select {
    border-radius: 22px;

    &.disabled {
      border-radius: 22px;
    }

    .dropdown-toggle {
      border-radius: 22px;
    }

    .dropdown-menu {
      width: 100%;
      padding: 0;
      border-radius: 0 0 22px 22px;
    }

    &.open {
      border-radius: 22px 22px 0 0;
    }
  }

  .v-select.reversed {
    &.open {
      border-radius: 0 0 22px 22px;
    }

    &.open .dropdown-toggle {
      border-radius: 0 0 22px 22px;
    }

    .dropdown-menu {
      bottom: 100%;
      top: auto;
      border-radius: 22px 22px 0 0;
    }
  }
}

.v-select--selected {
  button.clear {
    display: block;
    margin-right: 0 !important;

    span {
      display: none;
    }

    &::before {
      display:block;
      content: '';
      background-image: url("../assets/images/base-icons/reset.svg");
      width: 10px;
      height: 10px;
      background-repeat: no-repeat;
      background-size: 10px 10px;
    }
  }

  .open-indicator {
    display: none;
  }
}