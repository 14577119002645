
.register-success {
  height: 100vh;
  background-color: #e5ebf0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .footer {
    left: 0;
    right: 0;
    width: auto;
    position: relative;
  }
}

.register-success__logo {
  margin: 84px auto 0 !important;
}

.register-success__title {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #1b2938;
  margin-bottom: 12px;
}

.register-success__email {
  font-size: 14px;
  text-align: center;
  color: #8798ac;
}
