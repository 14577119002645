@font-face {
  font-family: "Eina02";
  src: url('../assets/fonts/Eina02-Regular.ttf');
}

@font-face {
  font-family: "Eina02";
  src: url('../assets/fonts/Eina02-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: "Eina02";
  src: url('../assets/fonts/Eina02-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: "Eina02";
  src: url('../assets/fonts/Eina02-SemiBold.ttf');
  font-weight: 500;
}

@font-face {
  font-family: "Eina02";
  src: url('../assets/fonts/Eina02-SemiBoldItalic.ttf');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Eina02";
  src: url('../assets/fonts/Eina02-RegularItalic.ttf');
  font-style: italic;
}

@font-face {
  font-family: "Eina02";
  src: url('../assets/fonts/Eina02-BoldItalic.ttf');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: "Eina02";
  src: url('../assets/fonts/Eina02-LightItalic.ttf');
  font-style: italic;
  font-weight: 300;
}
