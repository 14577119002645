
.two-fa-code__reset-link {
  padding-top: 30px;
  color: #8798ac;
  text-align: center;

  .button__link {
    font-weight: 500;
  }
}
