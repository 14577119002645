
.login-form__container {
  width: 475px;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.register-form__container {
  max-width: 404px;
}

.checkbox__container {
  margin-bottom: 30px;
}

.checkbox__updates,
.checkbox__terms {
  a {
    color: #2e4ce0;
  }
}

.checkbox__updates {
  margin-bottom: 60px;
}
