@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input {
  @apply text-ellipsis appearance-none;
}

.sr-only {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.overlay {
  @apply fixed top-0 left-0 bottom-0 right-0 bg-neutral-300 justify-center items-center z-[998];

  &--top {
    @apply z-[999];
  }

  &--no-paddings {
    @apply p-0 overflow-hidden #{!important};
  }

  &--bottom {
    @apply z-[11];
  }
}

.overlay--flex {
  @apply flex flex-col overflow-y-auto items-stretch justify-around;
}

.overlay--static {
  @apply relative min-h-full box-border;
}

.overlay--block {
  @apply block;
}

.overlay--justify-start {
  @apply content-start;
}


.overlay__close {
  @apply fixed top-[34px] left-[30px] z-[2];
}

.overlay__title {
  @apply text-xl sm:text-4xl font-medium text-center text-neutral-900;
}

.overlay__text {
  @apply text-md font-normal max-w-[600px] text-neutral-600 pt-3 sm:pt-5 px-0 pb-0 text-center my-0 mx-auto;
}

.overlay__button-wrapper {
  @apply pt-[60px] flex flex-col w-full sm:w-auto;
}

.overlay__wrapper {
  @apply max-w-full grow content-center;

  &--center {
    @apply my-auto mx-0;
  }
}

.overlay__form-wrapper {
  @apply max-w-full w-[394px] mt-[60px] mx-auto mb-0;

  .button {
    @apply w-full;
  }

  .overlay__button-wrapper {
    @apply pt-0;
  }
}

.button {
  outline: none;
  height: 48px;
  box-sizing: border-box;
  padding: 2px 30px 0;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.button__primary {
  border-radius: 24px;
  background-color: #3152dc;
  color: #e5ebf0;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #1335c3;
  }

  &:active {
    background-color: #1335c3;
    outline: 0;
    transform: translateY(2px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &.button--loading {
    background-image: none !important;
    color: rgb(0 0 0 / 0) !important;
    pointer-events: none;
  }

  &.button--loading::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    background: url('../assets/images/loaders/loading-spinner-white.svg') center center no-repeat;
    animation: rotate 1s linear infinite;
  }

  &--success {
    background-color: #25b05b;
    color: #dfffeb;

    &:hover {
      background-color: #168e44;
    }

    &:active {
      background-color: #168e44;
    }
  }

  &--white {
    background-color: #fcfdfd;
    color: #3152dc;

    &:hover,
    &:focus {
      background-color: #fff;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &.button--loading::after {
      background: url('../assets/images/loaders/loading-spinner.svg') center center no-repeat;
    }

    &--green-text {
      color: #25b05b;
    }
  }

  &--white--bordered {
    border: solid 2px #e5ebf0;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  &--wide {
    width: 100%;
    height: 48px;
    min-height: 48px;
  }

  &--medium {
    height: 40px;
    min-height: 40px;
    border-radius: 24px;
  }

  &--small {
    height: 32px;
    min-height: 32px;
    border-radius: 16px;
  }
}

.button--rect {
  border-radius: 12px;
}

.mt-3 {
  margin-top: 3px;
}

.mt-60 {
  margin-top: 60px;
}

.button__link {
  border: none;
  background: transparent;
  color: #3152dc;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    color: #1335c3;
  }

  &:focus {
    color: #1335c3;
  }

  &:disabled {
    opacity: 0.3;
  }

  &--white {
    color: #fcfdfd;

    &:hover,
    &:focus {
      color: #fff;
    }
  }

  &--red {
    color: #ff2373;
    display: inline-block;
    cursor: pointer;

    &:hover,
    &:focus {
      color: #ff2373;
    }
  }
}

.button--confirmation {
  min-width: 390px;

  &:last-child {
    margin-top: 10px;
  }

  @media only screen and (max-width: 767px) {
    min-width: 0;
    width: 100%;
    margin: 0;

    &.button__primary {
      box-shadow: 0 30px 50px 0 rgb(27 41 56 / 0.2);
    }
  }
}

.content-wrapper {
  @apply w-full flex flex-col;
}

.content {
  @apply flex flex-col grow max-w-full min-h-0 box-border sm:pt-[60px] sm:pr-[60px] sm:pb-[60px] sm:pl-[140px];

  &.add-posts {
    @apply pt-0 pr-[60px] pb-[60px] pl-[140px];
  }

  &.posts {
    padding: 30px 0 0;
  }

  &--top-left {
    @apply max-h-full pt-0 pr-0 pb-[60px] sm:pb-0 sm:pl-[80px];
  }
}

.center-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 60px;

  @media only screen and (max-width: 767px) {
    padding: 20px 20px 15px;
  }
}

.login-header__link {
  font-weight: 500;
  color: #3152dc;
}

.register__go-to-sign-in {
  color: #8798ac;
  margin-top: 30px;
}

.content__paladin-logo {
  margin-bottom: 80px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 60px;
  }
}

.notice {
  position: fixed;
  top: 110px;
  left: 50%;
  transform: translate(-50%, 0) scale(0);
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  padding: 12px 60px;
  opacity: 0;
  overflow: hidden;
  z-index: 1000;
  box-shadow: 0 30px 50px 0 rgb(27 41 56 / 0.2);
  animation: fade-in 0.65s ease-in forwards;

  &.show {
    animation: scale-in 0.3s ease-out forwards;
  }

  @media only screen and (max-width: 767px) {
    width: calc(100% - 70px);
    padding: 12px 15px;
    text-align: center;
  }
}

.notice--success {
  background-color: #e2feec;
  color: #37d273;
}

.notice--error {
  background-color: #fff2f2;
  color: #ff2373;
}

.notice--info {
  background-color: #daedf8;
  color: #8798ac;
}

.notice__link {
  font-weight: 500;
  color: #3152dc;

  &:hover {
    color: #1335c3;
  }

  &:active {
    transform: translateY(2px);
  }
}

.notice--closable {
  padding-right: 50px;
  text-align: left;

  @media only screen and (max-width: 767px) {
    width: calc(100% - 105px);
  }
}

.notice__close {
  position: absolute;
  top: 0;
  bottom: 2px;
  right: 15px;
  margin: auto 0;
  cursor: pointer;
  opacity: 0.9;
}

@keyframes scale-in {
  100% {
    transform: translate(-50%, 0) scale(1);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}

.main-content {
  @apply flex w-full relative flex-col sm:pt-[80px] grow pt-[44px] pb-[60px] sm:pb-0;

  &.main-content--page-dashboard {
    > .footer {
      @apply sm:hidden;
    }
  }
}

.copyright {
  color: #8798ac;
  margin: 20px 0;
  text-align: center;
}

.syncing-icon {
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.picker-bg {
  position: fixed;
  top: -100vh;
  left: -100vw;
  right: -100vw;
  bottom: -100vh;
  z-index: 7; // opened pickers have 9
}

.reset-filter {
  position: absolute;
  background: url('../assets/images/base-icons/reset.svg');
  color: #ff2373;
  cursor: pointer;
  height: 10px;
  width: 10px;
  font-weight: 500;
  margin: auto 0;
  right: 10px;
  top: 0;
  bottom: 0;
  z-index: 8;

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.apply-filter-mobile--wrap {
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 20px 20px;
    background: #3152dc;
    z-index: 9;
    grid-row: 5 / 6;
  }
}

.apply-filter-mobile {
  border-radius: 24px;
  border: solid 2px #fcfdfd;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #fcfdfd;
}

.content__head {
  .search-input__wrapper {
    display: flex;
    align-items: center;
  }
}

.posts-buttons-wrapper {
  position: fixed;
  bottom: 91px;
  z-index: 9;
  justify-content: center;
  left: 80px;
  right: 0;
  height: 0;
  display: flex;

  @media only screen and (max-width: 767px) {
    left: 0;
  }
}

.posts-buttons-icon {
  cursor: pointer;
  box-shadow: 0 30px 50px 0 rgb(27 41 56 / 0.4);
  border-radius: 12px;
  background-color: #3152dc;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    transform: translateY(2px);
  }
}

.posts-buttons-icon__remove {
  background: url('../assets/images/base-icons/bin.svg') center center no-repeat #3152dc;
  height: 50px;
  width: 50px;
  border-radius: 10px;

  &:hover {
    background: url('../assets/images/base-icons/bin.svg?fill=#fff') center center no-repeat #ff2373;
  }

  &:disabled {
    background: url('../assets/images/base-icons/bin.svg?fill=#3152dc') center center no-repeat #1335c3;
  }
}

.chart-tooltip,
.datamaps-hoverover {
  position: fixed;
  border-radius: 3px;
  box-shadow: 0 10px 20px 0 rgb(27 41 56 / 0.4);
  background-color: #1b2938;
  padding: 20px;
  transform: translate(-50%, -110%);
  pointer-events: none;
  text-align: left;
  color: #e5ebf0;
  white-space: nowrap;
  z-index: 8;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 4px solid #1b2938;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }

  &.right {
    transform: translate(calc(-100% + 20px), -110%);

    &::after {
      right: 16px;
      left: auto;
      transform: none;
    }
  }

  &.left {
    transform: translate(-20px, -110%);

    &::after {
      left: 16px;
      transform: none;
    }
  }
}

.chart-tooltip-line {
  position: absolute;
  top: 5px;
  bottom: 22px;
  width: 2px;
  background: #ff2373;
  transform: translateX(-1px);
  pointer-events: none;
}

.chart-tooltip-date {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
}

.chart-tooltip-label {
  font-size: 30px;
  font-weight: 500;
  margin-top: 5px;

  @media only screen and (max-width: 767px) {
    font-size: 24px;
  }
}

.chart-tooltip-label-comment {
  font-size: 10px;
  font-weight: normal;
  padding-left: 20px;
  margin-top: -3px;
}

.chart-tooltip-sublabel {
  font-size: 10px;
  font-weight: 500;
}

.chart-tooltip-subheader {
  color: #8798ac;
  font-size: 10px;
  margin-top: 5px;
}

.chart-tooltip-dot {
  width: 0;
  height: 0;
  border-radius: 50%;
  border-width: 5px;
  border-style: solid;
  display: inline-block;
  vertical-align: 5px;
}

.chart-tooltip-dot--normal {
  border-width: 2px;
  width: 6px;
  height: 6px;
  vertical-align: -1px;
}

.chart-tooltip-dot--trend {
  vertical-align: -1px;
}

.chart-tooltip-dot--estimated {
  vertical-align: 2px;
}

.chart-tooltip-normal-label, .chart-tooltip-trend-label {
  font-size: 10px;
  color: #8798ac;
  margin: 10px 0 5px;
  font-weight: 400;
}

.chart-tooltip-normal-value {
  font-size: 12px;
  line-height: 1;
  color: #ffb904;
  font-weight: 500;
}

.chart-tooltip-trend-value {
  font-size: 12px;
  line-height: 1;
  color: #C6CFD6;
  font-weight: 500;
}

.chart-tooltip-timestamp {
  font-size: 12px;
  line-height: 1;
  color: #fff;
  font-weight: 500;
}

.chart-tooltip-growth {
  font-size: 12px;
  font-weight: 500;
  padding-left: 20px;

  &--plus {
    color: #5bd98c;
  }

  &--minus {
    color: #ff2373;
  }
}

.chart-tooltip--multiline {
  .chart-tooltip-label {
    font-size: 12px;
    margin-top: 10px;
    white-space: nowrap;
    padding-right: 70px;
    position: relative;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (max-width: 767px) {
      max-width: 100px;
    }
  }

  .analytics-graph-tooltip-value {
    position: absolute;
    right: 0;
    display: inline-block;
  }

  .chart-tooltip-dot {
    margin-right: 6px;
    vertical-align: 0;
  }

  .chart-tooltip-dot--estimated {
    vertical-align: -3px;
  }
}

.export-email__button {
  height: 48px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../assets/images/arrows/arrow-up-thick.svg') 30px center no-repeat #fcfdfd;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #3152dc;
  margin-left: auto;
  padding: 0 30px 0 52px;

  &:hover {
    background: url('../assets/images/arrows/arrow-up-thick.svg') 30px center no-repeat #fff;
  }

  &:active {
    outline: none;
    transform: translateY(2px);
    background: url('../assets/images/arrows/arrow-up-thick.svg') 30px center no-repeat #fff;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--no-icon {
    background-image: none !important;
    padding: 0 30px;
  }

  &.button__primary--white--bordered {
    &:disabled {
      background: url('../assets/images/arrows/arrow-up-thick.svg') 30px center no-repeat #fcfdfd;
      cursor: not-allowed;
      color: #3152dc;
      opacity: 0.3;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    box-shadow: 0 30px 50px 0 rgb(27 41 56 / 0.3);
    position: fixed;
    background: url('../assets/images/arrows/arrow-up-thick.svg') center center no-repeat #fcfdfd !important;
    background-size: 22px 23px;
    padding: 0;
    color: transparent;
    z-index: 8;
    border: 0;

    &:hover {
      background: url('../assets/images/arrows/arrow-up-thick.svg') center center no-repeat #fff !important;
      background-size: 22px 23px;
    }

    &:active {
      outline: none;
      transform: translateY(2px);
      background: url('../assets/images/arrows/arrow-up-thick.svg') center center no-repeat #fff !important;
      background-size: 22px 23px;
    }
  }
}

.floating-buttons {
  position: fixed;
  margin-top: 30px;
  right: 60px;
  display: flex;
  z-index: 2;

  .export-email__button {
    display: inline-block;
    margin-right: 10px;

    @media only screen and (max-width: 767px) {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}

.hidden {
  visibility: hidden;
}

.loading-default {
  animation: rotate 1s linear infinite;
  background: url('../assets/images/loaders/loading-spinner.svg') no-repeat;
  height: 30px;
  width: 30px;
  border: none !important;

  &::before {
    content: none !important;
  }

  &::after {
    content: none !important;
  }
}

.vdp-datepicker__calendar {
  border-radius: 12px;
  border: none;
}

.vdp-datepicker__calendar header .prev:not(.disabled):hover {
  border-radius: 12px 0 0;
}

.vdp-datepicker__calendar header .next:not(.disabled):hover {
  border-radius: 0 12px 0 0;
}

.full-width {
  width: 100%;
}

.reauth-button {
  background: #e02f2f;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 10px;
  line-height: 1;
  padding: 3px 15px;
  text-transform: uppercase;
  max-width: 74px;
  display: inline-block;

  &:disabled {
    cursor: default;
  }
}

.filtering-picker--options-wrapper {
  @media only screen and (max-width: 767px) {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1;
    padding: 20px;
    padding-top: 40px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #3152dc;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #4b6dff;
      border-radius: 4px;
    }

    &--block {
      display: block;
      padding: 0;
    }
  }
}

.hide-mobile {
  @media only screen and (max-width: 767px) {
    @apply hidden #{!important};
  }
}

.hide-desktop.hide-desktop.hide-desktop.hide-desktop {
  @media only screen and (min-width: 768px) {
    @apply hidden #{!important};
  }
}

.ui-icon-gripsmall-diagonal-se {
  z-index: 7 !important;
}

.member-details {
  padding: 25px;
  background: #FCFDFD;
  border-radius: 8px;

  @media only screen and (max-width: 767px) {
    background: #e5ebf0;
    overflow-x: hidden;
    padding: 20px;
  }
}

.member-details__header {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 25px;
}

.member-details__tabs {
  position: absolute;
  bottom: -40px;

  @media only screen and (max-width: 767px) {
    bottom: -20px;
  }
}

.member-details__personal-info-wrapper {
  display: flex;
}

.member-details__header-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  gap: 4px;
}

.member-details__header-description {
  color: #8798ac;
  font-size: 12px;
  font-weight: 400;
}

.member-details__section {
  margin-top: 60px;
  word-break: break-word;

  &:first-of-type {
    margin-top: 0;
  }
}

.member-details__info-block {
  @media only screen and (max-width: 767px) {
    margin-top: 30px;
  }
}

.member-details__row {
  @apply gap-y-8;
}

.member-details__info-label {
  color: #8798ac;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.member-details__avatar {
  border-radius: 6px;
  height: 40px;
  width: 40px;
  background-image: url("../assets/images/backgrounds/default-avatar.svg");
  background-size: contain;
  background-repeat: no-repeat;

  @media only screen and (max-width: 767px) {
    height: 100px;
    width: 100px;
    background-image: url("../assets/images/backgrounds/avatar-placeholder.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.member-details__info-detail {
  width: 100%;
  color: #1b2938;
  font-size: 18px;
  letter-spacing: -0.9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}

.member-details__name {
  color: #1b2938;
  font-size: 14px;
  letter-spacing: -0.9px;
  margin-bottom: 2px;
  font-weight: 500;
}

.member__permissions-heading {
  color: #8798ac;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 30px;
  padding-left: 30px;
}

.member-details__delete {
  background: transparent;
  color: #ff2373;
  font-size: 16px;
  font-weight: 500;
  margin-top: 60px;
}

.white-area-overlay__close {
  @media only screen and (max-width: 767px) {
    background-color: #fcfdfd;
    display: block;
    left: 6px;
    top: 4px;
    position: fixed;
  }
}

.input-settings {
  margin-bottom: 30px;

  &--small {
    margin-bottom: 0;

    input {
      width: 34px !important;
      padding: 2px 14px 0 !important;
    }

    label,
    .error-message {
      display: none !important;
    }
  }
}

.input-settings label {
  width: fit-content;
  display: inline-block;
  color: #8798ac;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
}

.input-settings input[type="text"],
.input-settings input[type="number"],
.input-settings input[type="password"],
.input-settings input[type="email"],
.input-settings input[name="phone"] {
  background: #fcfdfd;
  border: 2px #e5ebf0 solid;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 20px 0;
  width: calc(100% - 44px);
  height: 42px;

  &:hover {
    background: #fffff5;
  }

  &:focus {
    background: #fffee2;
    outline: none;
  }

  &::placeholder {
    color: #8798ac;
    font-weight: 400;
    line-height: 24px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background: #fcfdfd;
    }
  }
}

// Hide arrows on number input
.input-settings input[type="number"]::-webkit-outer-spin-button,
.input-settings input[type="number"]::-webkit-inner-spin-button, {
  appearance: none;
  margin: 0;
}

.input-settings input[type="number"] {
  appearance: textfield;
}

.input-settings input[name="phone"] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  float: left;
  width: calc(100% - 124px);
}

.input-settings__textarea {
  height: 310px;
  background: #fcfdfd;
  border: 2px #e5ebf0 solid;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 20px 0;
  width: calc(100% - 44px);
  resize: none;

  &:hover {
    background: #fffff5;
  }

  &:focus {
    background: #fffee2;
    outline: none;
  }

  &::placeholder {
    color: #8798ac;
    font-weight: 400;
  }
}

.input-settings--phone {
  display: flex;
}

.member-details__info-wrapper {
  margin-bottom: 30px;
}

.button-settings-save {
  margin-top: 30px;
}

.calendly-overlay {
  .calendly-popup-content {
    @apply overflow-hidden #{!important};
  }

  .calendly-popup-close {
    background-image: url('../assets/images/base-icons/close.svg?fill=#000') !important;
    background-color: #fff !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 17px 17px !important;
    width: 35px !important;
    height: 35px !important;
    border-radius: 50%;
  }
}
